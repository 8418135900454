import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import Loader from 'components/layout-components/Loader';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';
import MainLayout from 'layouts/Main';
import PublicLayout from 'layouts/Public';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getCompany, getUser } from 'redux/selectors';
import { v4 as uuidv4 } from 'uuid';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const userRoutes = [
  {
    path: 'login',
    component: loadable(() => import('pages/public/login')),
    exact: true,
  },
  {
    path: 'forgot',
    component: loadable(() => import('pages/public/forgot')),
    exact: true,
  },
  {
    path: 'reset-password',
    component: loadable(() => import('pages/public/reset')),
    exact: true,
  },
];

const privateRoutes = [
  // Profile
  {
    path: '/profile/manage',
    component: loadable(() => import('pages/profile/manage')),
  },
  {
    path: '/profile/duty',
    component: loadable(() => import('pages/profile/duty')),
  },

  // Data
  {
    path: '/data/airports',
    component: loadable(() => import('pages/data/airports/AirportsPage')),
    exact: true,
  },
  {
    path: '/data/organization/users',
    component: loadable(() => import('pages/data/organization/users')),
    exact: true,
  },
  {
    path: '/data/organization/aircraft',
    component: loadable(() => import('pages/data/organization/aircraft')),
    exact: true,
  },
  {
    path: '/data/organization/fleet-overview',
    component: loadable(() => import('pages/data/organization/fleet-overview')),
    exact: true,
  },
  {
    path: '/data/organization/aerochex',
    component: loadable(() => import('pages/data/organization/aerochex')),
    exact: true,
  },
  {
    path: '/data/organization/logs',
    component: loadable(() => import('pages/data/organization/logs')),
    exact: true,
  },
  {
    path: '/data/organization/flights',
    component: loadable(() => import('pages/data/organization/flights')),
    exact: true,
  },
  {
    path: '/data/organization/duty-times',
    component: loadable(() => import('pages/data/organization/duty-times')),
    exact: true,
  },
  {
    path: '/data/organization/crew-compliance',
    component: loadable(() => import('pages/data/organization/crew-compliance')),
    exact: false,
  },
  {
    path: '/data/users/:id',
    component: loadable(() => import('pages/data/user-details')),
    exact: true,
  },
  {
    path: '/data/aircraft/logs/:id',
    component: loadable(() => import('pages/data/aircraft-log/AircraftLogPage')),
    exact: true,
  },
  {
    path: '/data/aircraft/:id',
    component: loadable(() => import('pages/data/aircraft-details/AircraftDetailsPage')),
    exact: true,
  },
  {
    path: '/data/organization/crew-scheduling',
    component: loadable(() => import('pages/data/organization/crew-scheduling')),
    exact: true,
  },
  {
    path: '/data/organization/crew-scheduling-v3',
    component: loadable(() => import('pages/data/organization/crew-scheduling-v3')),
    exact: true,
  },
  {
    path: '/data/organization/crew-scheduling-mobile',
    component: loadable(() => import('pages/data/organization/crew-scheduling-mobile')),
    exact: true,
  },
  {
    path: '/data/organization/dashboard',
    component: loadable(() => import('pages/data/organization/dashboard')),
    exact: true,
  },
  {
    path: '/data/organization/regulations',
    component: loadable(() => import('pages/data/organization/regulations')),
    exact: true,
  },
  {
    path: '/data/organization/reports',
    component: loadable(() => import('pages/data/organization/reports')),
    exact: true,
  },
  {
    path: '/data/organization/settings',
    component: loadable(() => import('pages/data/organization/settings')),
    exact: true,
  },
  {
    path: '/data/organization/flight-watch',
    component: loadable(() => import('pages/data/organization/flight-watch')),
    exact: true,
  },
  {
    path: '/data/organization/pilot-status',
    component: loadable(() => import('pages/data/organization/pilot-status')),
    exact: true,
  },
  {
    path: '/data/organization/company-library',
    component: loadable(() => import('pages/data/organization/company-library')),
    exact: true,
  },
  {
    path: '/data/organization/tracked-items',
    component: loadable(() => import('pages/data/organization/tracked-items')),
    exact: true,
  },
  {
    path: '/data/organization/custom-forms',
    component: loadable(() => import('pages/data/organization/custom-forms')),
    exact: true,
  },
  // {
  //   path: '/settings/timezone',
  //   component: loadable(() => import('pages/settings/timezone')),
  //   exact: true,
  // },

  // // Admin
  // {
  //   path: '/admin',
  //   component: loadable(() => import('pages/admin')),
  //   exact: true,
  // },
];

export default function Router({ history }) {
  const company = useSelector(getCompany);
  const user = useSelector(getUser);

  const [companySettings, setCompanySettings] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [currentTimezone, setCurrentTimezone] = useState('');

  useEffect(() => {
    if (company && user) {
      if (company?.Settings) {
        setCompanySettings({
          CanMemberChangeTimezone: company.Settings.CanMemberChangeTimezone,
          CompanyDefaultTimezone: company.Settings.CompanyDefaultTimezone,
        });
      }
      if (user?.Profile.Metadata) {
        setUserSettings({
          SelectedTimeZone: user.Profile.Metadata.SelectedTimeZone
            ? user.Profile.Metadata.SelectedTimeZone
            : '',
        });
      }
    }
  }, [company, user]);

  useEffect(() => {
    if (companySettings?.CanMemberChangeTimezone && userSettings?.SelectedTimeZone.length > 0) {
      setCurrentTimezone(userSettings.SelectedTimeZone);
      moment.tz.setDefault(userSettings.SelectedTimeZone);
    } else if (companySettings?.CompanyDefaultTimezone) {
      setCurrentTimezone(companySettings.CompanyDefaultTimezone);
      moment.tz.setDefault(companySettings.CompanyDefaultTimezone);
    } else {
      const defaultZone = moment.tz.zone(moment.tz.guess()).name;
      setCurrentTimezone(defaultZone);
      moment.tz.setDefault(defaultZone);
    }
  }, [userSettings, companySettings]);

  const timezoneInUtc = company?.Settings?.InputTimeInUTC;

  if (timezoneInUtc) {
    // the default is local time zone so we only reset the default when we want UTC
    // moment.tz.setDefault('UTC');
  }

  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/data/organization/aircraft" />} />
          <Redirect from="/data/organization/crew-scheduling-v2" to="/data/organization/crew-scheduling-v3" />
          <Route path="/user">
            <PublicRoutes />
          </Route>
          <Route path="/">
            <IndexLayout>
              <MainLayout>
                <Switch>
                  {privateRoutes.map(route => (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                      exact={route.exact}
                    />
                  ))}
                </Switch>
              </MainLayout>
            </IndexLayout>
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  );
}

function PublicRoutes() {
  const { path } = useRouteMatch();

  return (
    <PublicLayout>
      <Switch>
        {userRoutes.map(route => (
          <Route
            path={`${path}/${route.path}`}
            component={route.component}
            exact={route.exact}
            key={uuidv4()}
          />
        ))}
      </Switch>
    </PublicLayout>
  );
}
